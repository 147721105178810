<template>
	<Dialog :header="$t('Anular')" v-model:visible="this.$store.state.void" :style="{width: '350px'}" :modal="true">
		<br><br>
        <div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<strong><span>{{$t('Anular')}}: N° {{ $store.state.void_data.id }}</span></strong>
		</div>
		<template #footer>
			<Button :label="$t('No')" icon="pi pi-times" @click="NO()" class="p-button-primary"/>
			<Button :label="$t('Sí')" icon="pi pi-check" @click="SI()" class="p-button-primary" autofocus />
		</template>
	</Dialog>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
    import API from "../service/ApiConsulta";

	export default {
        props: {
            caso: {
                type: String,
                default: '',
            },
        },
        data() {
			return {
				i18n: null,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
		},
        methods: {
            Refrescar(event) {
                this.$emit('act-eval', event);
            },
            NO(){
                this.$store.state.void = false;
            },           
            SI(){
                this.$store.state.void = false;
                this.$store.commit('Loading');
				const Consulta = new API('Evaluacion');          
				Consulta.Procesar(this.caso,{
					caso: 	'VOID',
					id: this.$store.state.void_data.id,
				}).then(response => {
                    this.$store.commit('Loading');
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Refrescar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
				});
                
            }
        }
	}
</script>
